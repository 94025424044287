const initialState = {
  loading: false,
  account: "",
  totalAuctions: 0,
  wrappedBalance: 0,

  approveAllowanceWewt: 0,
  approveAllowanceShl: 0,
  approveAllowanceRenbtc: 0,
  approveAllowanceWeth: 0,
  approveAllowanceDai: 0,
  approveAllowanceOcean: 0,
  approveAllowanceCc: 0,
  approveAllowanceSmudge: 0,
  approveAllowanceSusu: 0,

  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        totalAuctions: action.payload.totalAuctions,
        wrappedBalance: action.payload.wrappedBalance,

        approveAllowanceWewt: action.payload.approveAllowanceWewt,
        approveAllowanceShl: action.payload.approveAllowanceShl,
        approveAllowanceRenbtc: action.payload.approveAllowanceRenbtc,
        approveAllowanceWeth: action.payload.approveAllowanceWeth,
        approveAllowanceDai: action.payload.approveAllowanceDai,
        approveAllowanceOcean: action.payload.approveAllowanceOcean,
        approveAllowanceCc: action.payload.approveAllowanceCc,
        approveAllowanceSmudge: action.payload.approveAllowanceSmudge,
        approveAllowanceSusu: action.payload.approveAllowanceSusu,

        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
