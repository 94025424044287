import Header from './components/Header';
import Home from './pages/Home';
import Create from './pages/Create';
import SaleHistory from './pages/SaleHistory';
import AuctionHistory from './pages/AuctionHistory';
import About from './pages/About';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-auction" element={<Create />} />
          <Route path="/sale-history" element={<SaleHistory />} />
          <Route path="/auction-history" element={<AuctionHistory />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
