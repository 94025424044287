import { useState, useEffect, useRef, useCallback } from 'react';
import truncateEthAddress from 'truncate-eth-address';
import { AiOutlineLink } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { connect } from '../redux/blockchain/blockchainActions';
import { db } from '../firebase';
import {  onValue, ref } from 'firebase/database';
import ReactCanvasConfetti from 'react-canvas-confetti';

// collections
//import tubbyturtles from '../assets/images/tubbyturtles.png';
//import cryptosoots from '../assets/images/cryptosoots.png';
//import nfttrees from '../assets/images/nfttrees.png';
//import iinu from '../assets/images/iinu.png';
// tokens
import WEWT from '../assets/images/wrappedewtlogo.png';
import SHL from '../assets/images/shllogo.png';
import RENBTC from '../assets/images/renbtclogo.png';
import WETH from '../assets/images/ethlogo.png';
import DAI from '../assets/images/dailogo.png';
import OCEAN from '../assets/images/oceanlogo.png';
import CC from '../assets/images/cclogo.png';
import SMUDGE from '../assets/images/smudgelogo.png';
import SUSU from '../assets/images/susulogo.png';
//
import UNKNOWN from '../assets/images/unknown.png';

function AuctionCard({ nftAddress, nftId, tokenAddress, startPrice, startTime, duration, nftImgLink, currentBid, auctionId, highestBidderAddress, creator, assetClaimer, tokensClaimer }) {
    /* global BigInt */
    const dispatch = useDispatch();

    duration = parseInt(duration);
    startTime = parseInt(startTime);
    let startTimeUnix = startTime;
    let endTimeUnix = startTime + duration;
    let currentTimeUnix = Math.round(Date.now() / 1000);

    let endTime = new Date((startTime + duration) * 1000).toLocaleString();
    startTime = new Date(startTime * 1000).toLocaleString();

    const calculateTimeLeft = () => {
        let difference = +new Date((startTimeUnix + duration) * 1000) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            }
        }
        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

    //
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        WEWT_CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        RENBTC_CONTRACT_ADDRESS: "",
        WETH_CONTRACT_ADDRESS: "",
        DAI_CONTRACT_ADDRESS: "",
        OCEAN_CONTRACT_ADDRESS: "",
        CC_CONTRACT_ADDRESS: "",
        SMUDGE_CONTRACT_ADDRESS: "",
        SUSU_CONTRACT_ADDRESS: "",

        TURTLES_CONTRACT_ADDRESS: "",
        SOOTS_CONTRACT_ADDRESS: "",
        TREES_CONTRACT_ADDRESS: "",
        IINU_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);
    //

    const [mmconnected, setMmconnected] = useState(false);
    useEffect(() => {
        if (blockchain.account !== "" && blockchain.account !== null) {
            setMmconnected(true);
        }
    }, [blockchain.account]);

    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    const canvasStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }

    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
            refAnimationInstance.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio)
            });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.2, {
            spread: 15,
            startVelocity: 50,
            decay: 0.85,
        });

        makeShot(0.15, {
            spread: 25,
            startVelocity: 45,
            decay: 0.8,
        });

    }, [makeShot]);

    const [externalLink, setExternalLink] = useState("");
    const [nftCollectionName, setNftCollectionName] = useState("");
    useEffect(() => {
        if (nftAddress.toLowerCase() === CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase()) {
            setExternalLink(`https://tubbyturtles.com/${nftId}`);
            setNftCollectionName('Tubby Turtles');
        }
        else if (nftAddress.toLowerCase() === CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase()) {
            setExternalLink(`https://soots.cc/${nftId}`);
            setNftCollectionName('Cryptosoots');
        }
        else if (nftAddress.toLowerCase() === CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase()) {
            setExternalLink(`https://gateway.pinata.cloud/ipfs/QmQFjEEAQzgGjptsA5wFY7K8J5HxrBG1FW1WszJMW6NN4f/${nftId}.png`);
            setNftCollectionName('Iinu');
        }
        else if (nftAddress.toLowerCase() === CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase()) {
            setExternalLink(`https://nftrees.cc/${nftId}`);
            setNftCollectionName('NFTrees');
        }
        else {
            setExternalLink(``);
            setNftCollectionName('Unlisted');
        }
    }, [CONFIG]);

    const [token, setToken] = useState("");
    useEffect(() => {
        if (tokenAddress.toLowerCase() === CONFIG.WEWT_CONTRACT_ADDRESS.toLowerCase()) { setToken('WEWT'); }
        else if (tokenAddress.toLowerCase() === CONFIG.SHL_CONTRACT_ADDRESS.toLowerCase()) { setToken('SHL'); }
        else if (tokenAddress.toLowerCase() === CONFIG.RENBTC_CONTRACT_ADDRESS.toLowerCase()) { setToken('RENBTC'); }
        else if (tokenAddress.toLowerCase() === CONFIG.WETH_CONTRACT_ADDRESS.toLowerCase()) { setToken('WETH'); }
        else if (tokenAddress.toLowerCase() === CONFIG.DAI_CONTRACT_ADDRESS.toLowerCase()) { setToken('DAI'); }
        else if (tokenAddress.toLowerCase() === CONFIG.OCEAN_CONTRACT_ADDRESS.toLowerCase()) { setToken('OCEAN'); }
        else if (tokenAddress.toLowerCase() === CONFIG.CC_CONTRACT_ADDRESS.toLowerCase()) { setToken('CC'); }
        else if (tokenAddress.toLowerCase() === CONFIG.SMUDGE_CONTRACT_ADDRESS.toLowerCase()) { setToken('SMUDGE'); }
        else if (tokenAddress.toLowerCase() === CONFIG.SUSU_CONTRACT_ADDRESS.toLowerCase()) { setToken('SUSU'); }
        else {
            setToken(tokenAddress);
        }
    }, [CONFIG])

    const [ewtAllowance, setEwtAllowance] = useState(0);
    const [shlAllowance, setShlAllowance] = useState(0);
    const [renbtcAllowance, setRenbtcAllowance] = useState(0);
    const [wethAllowance, setWethAllowance] = useState(0);
    const [daiAllowance, setDaiAllowance] = useState(0);
    const [oceanAllowance, setOceanAllowance] = useState(0);
    const [ccAllowance, setCcAllowance] = useState(0);
    const [smudgeAllowance, setSmudgeAllowance] = useState(0);
    const [susuAllowance, setSusuAllowance] = useState(0);
    const [unknownTokenApproved, setUnknownTokenApproved] = useState(false);
    function getAllowanceOfTokens() { 
        setEwtAllowance(data.approveAllowanceWewt); 
        setShlAllowance(data.approveAllowanceShl); 
        setRenbtcAllowance(data.approveAllowanceRenbtc); 
        setWethAllowance(data.approveAllowanceWeth); 
        setDaiAllowance(data.approveAllowanceDai); 
        setOceanAllowance(data.approveAllowanceOcean); 
        setCcAllowance(data.approveAllowanceCc); 
        setSmudgeAllowance(data.approveAllowanceSmudge); 
        setSusuAllowance(data.approveAllowanceSusu); 
    }

    useEffect(() => {
        getAllowanceOfTokens();
    }, [data])


    async function approveTokenForBidding() {
        let totalCostWei = 0;
        if (tokenAddress.toLowerCase() === CONFIG.WEWT_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.wewtContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // shl
        else if (tokenAddress.toLowerCase() === CONFIG.SHL_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.shlContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+27))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // renbtc
        else if (tokenAddress.toLowerCase() === CONFIG.RENBTC_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.renbtcContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // weth
        else if (tokenAddress.toLowerCase() === CONFIG.WETH_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.wethContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // dai
        else if (tokenAddress.toLowerCase() === CONFIG.DAI_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.daiContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // ocean
        else if (tokenAddress.toLowerCase() === CONFIG.OCEAN_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.oceanContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // cc
        else if (tokenAddress.toLowerCase() === CONFIG.CC_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.ccContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // smudge
        else if (tokenAddress.toLowerCase() === CONFIG.SMUDGE_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.smudgeContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+25))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
        // susu
        else if (tokenAddress.toLowerCase() === CONFIG.SUSU_CONTRACT_ADDRESS.toLowerCase() ) {
            blockchain.susuContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS), BigInt(1e+27))
            .send({
                to: tokenAddress.toLowerCase(),
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    setTimeout(function () {
                        getAllowanceOfTokens();
                        setUnknownTokenApproved(true);
                    }, 1000);
                }
            });
        }
    }

    const [auctionsList, setAuctionsList] = useState([]);

    const getSnapshotChildren = (snapshot) => {
        const children = [];
        snapshot.forEach(child => { children.push(child.key) })
        return children;
    }

    useEffect(() => {
        onValue(ref(db), snapshot => {
            const auctions = getSnapshotChildren(snapshot);
            setAuctionsList(auctions);
        });
    }, []);

    function bidOnNFT() {
        console.log(auctionsList);
        let totalCostWei = 0;
        let weiValue = 0;
        try {
            //if (document.getElementById('bigAmountInput').value % 1 !== 0) {
            //    weiValue = BigInt(Math.round(document.getElementById('bigAmountInput').value * 10000)) * BigInt(1e+18) / BigInt(10000);
            //}
            //else {
                //console.log(document.getElementById('bigAmountInput').value)
                console.log(document.getElementById(`bigAmountInput${auctionId}`).value)
                weiValue = BigInt(document.getElementById(`bigAmountInput${auctionId}`).value * 10000) * BigInt(1e+18) / BigInt(10000);
            //}
            console.log(weiValue)
            blockchain.smartContract.methods.bid(String(auctionId), String(weiValue))
                .send({
                    to: CONFIG.CONTRACT_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                    gasPrice: 100000000,
                }).then((receipt) => {
                    console.log({ receipt }.receipt)
                    if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                        setTimeout(function () {
                            getAllowanceOfTokens();
                            fire();
                        }, 1000);
                    }
                });
        }
        catch {
            document.getElementById(`bigAmountInput${auctionId}`).value = 'The max. amount of decimals is 4';
        }
    }

    function claimAuctionAsset() {
        let totalCostWei = 0;
        blockchain.smartContract.methods.claimAsset(String(auctionId))
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    return
                }
            });
    }

    function claimAuctionTokens() {
        let totalCostWei = 0;
        blockchain.smartContract.methods.claimTokens(String(auctionId))
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log({ receipt }.receipt)
                if ({ receipt }.receipt.blockHash !== undefined && { receipt }.receipt.blockHash !== "") {
                    return
                }
            });
    }

    //useEffect(() => {
    //    console.log(currentBid)
    //    console.log(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000)
    //}, [])

    return (
        <div className="auctioncard
            dark:bg-gradient-to-l dark:from-[rgba(154,239,162,0.6)] dark:via-[rgba(141,215,228,0.6)] dark:to-[rgba(200,161,251,0.6)] 
            bg-gradient-to-l from-[rgba(54,139,62,0.6)] via-[rgba(41,115,128,0.6)] to-[rgba(100,61,151,0.6)]
            transition-all m-3 sm:m-4 md:m-5 lg:m-6 w-[340px] s:w-full h-[595px] s:h-[640px] xs:h-[610px] 2xs:h-[540px] 3xs:h-[480px] 4xs:h-[460px] 5xs:h-[440px] rounded-2xl
            dark:shadow-[0_0px_12px_3px_rgba(15,23,35,0.30)] shadow-[0_0px_12px_3px_rgba(245,245,230,0.2)] p-[3px] sm:p-[4px] md:p-[5px]">
            <div className='pb-2 h-full w-full dark:bg-white bg-gray-800 rounded-lg px-1 2xs:text-sm 3xs:text-xs'>
                <div className='flex flex-col justify-center h-full'>
                    {startTimeUnix > currentTimeUnix ? (
                        <div className='flex flex-row'>
                            <div className='w-5'>
                                <abbr className='no-underline' title="Auction ID">
                                    <p className='text-sm z-10 relative h-[0px] ml-2 mr-auto'>#{auctionId}</p>
                                </abbr>
                            </div>
                            <div className='mx-auto'>
                                <abbr title="External link to NFT">
                                    <a href={`${externalLink}`} target='_blank' rel='noreferrer'><AiOutlineLink className='hover:text-blue-500 active:text-purple-500' /></a>
                                </abbr>
                            </div>
                            <div className='w-5'>
                                <abbr title="Auction has not started yet">
                                    <div id='blinkingiconNotStarted' className='z-10 relative ml-auto mr-2 w-3 h-3 rounded-full'></div>
                                </abbr>
                            </div>
                        </div>
                    ) : (
                        currentTimeUnix < endTimeUnix ? (
                            <div className='flex flex-row'>
                                <div className='w-5'>
                                    <abbr title="Auction ID">
                                        <p className='text-sm z-10 relative h-[0px] ml-2 mr-auto'>#{auctionId}</p>
                                    </abbr>
                                </div>
                                <div className='mx-auto'>
                                    <abbr title="External link to NFT">
                                        <a href={`${externalLink}`} target='_blank' rel='noreferrer'><AiOutlineLink className='hover:text-blue-500 active:text-purple-500' /></a>
                                    </abbr>
                                </div>
                                <div className='w-5'>
                                    <abbr title="Auction is active">
                                        <div id='blinkingicon' className='z-10 relative ml-auto mr-2 w-3 h-3 rounded-full'></div>
                                    </abbr>
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-row'>
                                <div className='w-5'>
                                    <abbr title="Auction ID">
                                        <p className='text-sm z-10 relative h-[0px] ml-2 mr-auto'>#{auctionId}</p>
                                    </abbr>
                                </div>
                                <div className='mx-auto'>
                                    <abbr title="External link to NFT">
                                        <a href={`${externalLink}`} target='_blank' rel='noreferrer'><AiOutlineLink className='hover:text-blue-500 active:text-purple-500' /></a>
                                    </abbr>
                                </div>
                                <div className='w-5'>
                                    <abbr title="Auction has ended">
                                        <div id='blinkingiconEnded' className='z-10 relative ml-auto mr-2 w-3 h-3 rounded-full'></div>
                                    </abbr>
                                </div>
                            </div>
                        )
                    )}

                    <img className='hover:scale-[1.05] transition-all w-[60%] mx-auto aspect-square rounded-3xl p-3' src={nftImgLink} alt="" />
                    <h2 className='text-center'><span>Nft Collection: </span><span className='font-bold'><a href={`https://explorer.energyweb.org/token/${nftAddress}/token-transfers`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500'>{nftCollectionName}</a></span></h2>
                    <h2 className='text-center'><span>Nft ID: </span><span className='font-bold'>{nftId}</span></h2>
                    {blockchain.account === creator.toLowerCase() ? (
                        <h2 className='text-center'><span>Auction creator: </span><span className='font-bold'><a href={`https://explorer.energyweb.org/address/${creator}`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500'>{truncateEthAddress(creator)}</a></span><span className='
                        font-bold text-transparent bg-clip-text ml-[3px] 
                        bg-gradient-to-r from-[rgba(154,239,162,1)] via-[rgba(141,215,228,1)] to-[rgba(200,161,251,1)] 
                        dark:bg-gradient-to-r dark:from-[rgba(54,139,62,1)] dark:via-[rgba(41,115,128,1)] dark:to-[rgba(100,61,151,1)]
                        '>(You)</span></h2>
                    ) : (
                        <h2 className='text-center'><span>Auction creator: </span><span className='font-bold'><a href={`https://explorer.energyweb.org/address/${creator}`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500'>{truncateEthAddress(creator)}</a></span></h2>
                    )}
                    {token === 'WEWT' ? (
                        <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WEWT} alt="" /></span></h2>
                    ) : (
                        token === 'SHL' ? (
                            <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SHL} alt="" /></span></h2>
                        ) : (
                            token === 'RENBTC' ? (
                                <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={RENBTC} alt="" /></span></h2>
                            ) : (
                                token === 'WETH' ? (
                                    <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WETH} alt="" /></span></h2>
                                ) : (
                                    token === 'DAI' ? (
                                        <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={DAI} alt="" /></span></h2>
                                    ) : (
                                        token === 'OCEAN' ? (
                                            <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={OCEAN} alt="" /></span></h2>
                                        ) : (
                                            token === 'CC' ? (
                                                <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={CC} alt="" /></span></h2>
                                            ) : (
                                                token === 'SMUDGE' ? (
                                                    <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SMUDGE} alt="" /></span></h2>
                                                ) : (
                                                    token === 'SUSU' ? (
                                                        <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SUSU} alt="" /></span></h2>
                                                    ) : (
                                                        <h2 className='text-center'><span>Startprice: </span><span className='font-bold'>{(parseFloat(BigInt(startPrice)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={UNKNOWN} alt="" /></span></h2>
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )}
                    {token.slice(0, 2) === '0x' ? (
                        <h2 className='text-center'><span>Token: </span><span className='font-bold'><a href={`https://explorer.energyweb.org/token/${tokenAddress}/token-transfers`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500'>{truncateEthAddress(token)}</a></span></h2>
                    ) : (
                        <h2 className='text-center'><span>Token: </span><span className='font-bold'><a href={`https://explorer.energyweb.org/token/${tokenAddress}/token-transfers`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500'>{token}</a></span></h2>
                    )
                    }
                    <h2 className='text-center'><span>Start Time: </span><span className='font-bold'>{startTime}</span></h2>
                    <h2 className='text-center'><span>End Time: </span><span className='font-bold'>{endTime}</span></h2>
                    <div className='mx-2 my-2 py-1 rounded-lg dark:bg-[rgba(0,0,0,0.05)] bg-[rgba(255,255,255,0.05)]'>
                        {currentTimeUnix > endTimeUnix ? (
                            <div>
                                <h2 className='text-center'><span>Remaining Time: </span><span className='font-bold'>Auction has ended</span>
                                </h2>
                                {token === 'WEWT' && creator !== highestBidderAddress ? (
                                    <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WEWT} alt="" /></span></h2>
                                ) : (
                                    token === 'SHL' && creator !== highestBidderAddress ? (
                                        <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SHL} alt="" /></span></h2>
                                    ) : (
                                        token === 'RENBTC' && creator !== highestBidderAddress ? (
                                            <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={RENBTC} alt="" /></span></h2>
                                        ) : (
                                            token === 'WETH' && creator !== highestBidderAddress ? (
                                                <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WETH} alt="" /></span></h2>
                                            ) : (
                                                token === 'DAI' && creator !== highestBidderAddress ? (
                                                    <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={DAI} alt="" /></span></h2>
                                                ) : (
                                                    token === 'OCEAN' && creator !== highestBidderAddress ? (
                                                        <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={OCEAN} alt="" /></span></h2>
                                                    ) : (
                                                        token === 'CC' && creator !== highestBidderAddress ? (
                                                            <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={CC} alt="" /></span></h2>
                                                        ) : (
                                                            token === 'SMUDGE' && creator !== highestBidderAddress ? (
                                                                <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SMUDGE} alt="" /></span></h2>
                                                            ) : (
                                                                token === 'SUSU' && creator !== highestBidderAddress ? (
                                                                    <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SUSU} alt="" /></span></h2>
                                                                ) : (
                                                                    creator !== highestBidderAddress ? (
                                                                        <h2 className='text-center'><span>Winning Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={UNKNOWN} alt="" /></span></h2>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )}
                            </div>
                        ) : (
                            <div>
                                <h2 className='text-center'><span>Remaining Time: </span><span className='mx-[3px] font-bold'>{timeLeft.days}D</span><span className='mx-[3px] font-bold'>{timeLeft.hours}H</span><span className='mx-[3px] font-bold'>{timeLeft.minutes}M</span><span className='mx-[3px] font-bold'>{timeLeft.seconds}S</span></h2>
                                {creator !== highestBidderAddress ? (
                                    token === 'WEWT' ? (
                                        <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WEWT} alt="" /></span></h2>
                                    ) : (
                                        token === 'SHL' ? (
                                            <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SHL} alt="" /></span></h2>
                                        ) : (
                                            token === 'RENBTC' ? (
                                                <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={RENBTC} alt="" /></span></h2>
                                            ) : (
                                                token === 'WETH' ? (
                                                    <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={WETH} alt="" /></span></h2>
                                                ) : (
                                                    token === 'DAI' ? (
                                                        <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={DAI} alt="" /></span></h2>
                                                    ) : (
                                                        token === 'OCEAN' ? (
                                                            <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={OCEAN} alt="" /></span></h2>
                                                        ) : (
                                                            token === 'CC' ? (
                                                                <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={CC} alt="" /></span></h2>
                                                            ) : (
                                                                token === 'SMUDGE' ? (
                                                                    <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SMUDGE} alt="" /></span></h2>
                                                                ) : (
                                                                    token === 'SUSU' ? (
                                                                        <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={SUSU} alt="" /></span></h2>
                                                                    ) : (
                                                                        <h2 className='text-center'><span>Current Bid: </span><span className='font-bold'>{(parseFloat(BigInt(currentBid)/BigInt(1e+13))/100000).toString()}</span><span><img className='w-4 inline ml-1 -translate-y-[1px]' src={UNKNOWN} alt="" /></span></h2>
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                ) : (
                                    <h2 className='text-center'><span>You have to bid more than the startprice</span></h2>
                                )}

                            </div>
                        )}
                        {creator === highestBidderAddress ? (
                            currentTimeUnix > endTimeUnix ? (
                                <h2 className='text-center'><span>Highest bidder: </span><span className='font-bold'>Ended without a bid</span></h2>
                            ) : (
                                <h2 className='text-center'><span>Highest bidder: </span><span className='font-bold'>No bidder yet</span></h2>
                            )
                        ) : (
                            blockchain.account === highestBidderAddress.toLowerCase() ? (
                                <h2 className='text-center'><span>Highest bidder: </span><a href={`https://explorer.energyweb.org/address/${highestBidderAddress}`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500 font-bold'>{(String(highestBidderAddress).slice(0, 6)) + '...' + (String(highestBidderAddress).slice(-4))}</a><span className='
                                font-bold text-transparent bg-clip-text ml-[3px] 
                                bg-gradient-to-r from-[rgba(154,239,162,1)] via-[rgba(141,215,228,1)] to-[rgba(200,161,251,1)] 
                                dark:bg-gradient-to-r dark:from-[rgba(54,139,62,1)] dark:via-[rgba(41,115,128,1)] dark:to-[rgba(100,61,151,1)]
                                '>(You)</span></h2>
                            ) :
                                (
                                    <h2 className='text-center'><span>Highest bidder: </span><a href={`https://explorer.energyweb.org/address/${highestBidderAddress}`} target='_blank' rel='noreferrer' className='hover:text-blue-500 active:text-purple-500 font-bold'>{(String(highestBidderAddress).slice(0, 6)) + '...' + (String(highestBidderAddress).slice(-4))}</a></h2>
                                )
                        )}
                    </div>
                    <div className='w-full '>
                        {currentTimeUnix > endTimeUnix ? (
                            <div className='w-[90%] flex flex-row items-center justify-center mx-auto'>

                                {blockchain.account === "" || data.totalAuctions === 0 ? (
                                    <button onClick={startApp} className='h-[30px] w-1/2 bg-green-600 dark:bg-green-300 hover:brightness-110 rounded-lg flex items-center justify-center mx-2'>
                                        Connect
                                    </button>
                                ) : (
                                    assetClaimer !== "0x0000000000000000000000000000000000000000" && blockchain.account !== "" ? (
                                        <button disabled={true} onClick={claimAuctionAsset} className='h-[30px] w-1/2 bg-purple-900 dark:bg-purple-100 rounded-lg flex items-center justify-center mx-2'>
                                            Asset claimed
                                        </button>
                                    ) : (
                                        <button onClick={claimAuctionAsset} className='h-[30px] w-1/2 bg-purple-600 dark:bg-purple-300 hover:brightness-110 rounded-lg flex items-center justify-center mx-2'>
                                            Claim asset
                                        </button>
                                    )
                                )}
                                {blockchain.account === "" || data.totalAuctions === 0 ? (
                                    <button onClick={startApp} className='h-[30px] w-1/2 bg-green-600 dark:bg-green-300 hover:brightness-110 rounded-lg flex items-center justify-center mx-2'>
                                        Connect
                                    </button>
                                ) : (
                                    tokensClaimer !== "0x0000000000000000000000000000000000000000" ? (
                                        <button disabled={true} onClick={claimAuctionTokens} className='h-[30px] w-1/2 bg-purple-900 dark:bg-purple-100 rounded-lg flex items-center justify-center mx-2'>
                                            Tokens claimed
                                        </button>
                                    ) : (
                                        tokensClaimer === "0x0000000000000000000000000000000000000000" && highestBidderAddress === "0x0000000000000000000000000000000000000000" ? (
                                            <button disabled={true} onClick={claimAuctionTokens} className='h-[30px] w-1/2 bg-purple-900 dark:bg-purple-100 rounded-lg flex items-center justify-center mx-2'>
                                                Tokens claimed
                                            </button>
                                        ) : (
                                            <button onClick={claimAuctionTokens} className='h-[30px] w-1/2 bg-purple-600 dark:bg-purple-300 hover:brightness-110 rounded-lg flex items-center justify-center mx-2'>
                                                Claim tokens
                                            </button>
                                        )
                                    )
                                )}
                            </div>
                        ) : (
                            mmconnected === true ? (
                                <div>
                                    <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

                                    <div>
                                        {token === 'WEWT' && BigInt(ewtAllowance) < BigInt(1000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve WEWT</button>
                                        ) : (
                                            token === 'WEWT' && BigInt(ewtAllowance) >= BigInt(1000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of WEWT you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'SHL' && BigInt(shlAllowance) < BigInt(100000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve SHL</button>
                                        ) : (
                                            token === 'SHL' && BigInt(shlAllowance) >= BigInt(100000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of SHL you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'RENBTC' && BigInt(renbtcAllowance) < BigInt(1 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve renBTC</button>
                                        ) : (
                                            token === 'RENBTC' && BigInt(renbtcAllowance) >= BigInt(1 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of renBTC you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'WETH' && BigInt(wethAllowance) < BigInt(5 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve WETH</button>
                                        ) : (
                                            token === 'WETH' && BigInt(wethAllowance) >= BigInt(5 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of WETH you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'DAI' && BigInt(daiAllowance) < BigInt(5000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve DAI</button>
                                        ) : (
                                            token === 'DAI' && BigInt(daiAllowance) >= BigInt(5000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of DAI you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'OCEAN' && BigInt(oceanAllowance) < BigInt(10000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve OCEAN</button>
                                        ) : (
                                            token === 'OCEAN' && BigInt(oceanAllowance) >= BigInt(10000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of OCEAN you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'CC' && BigInt(ccAllowance) < BigInt(25000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve CC</button>
                                        ) : (
                                            token === 'CC' && BigInt(ccAllowance) >= BigInt(25000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of CC you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'SMUDGE' && BigInt(smudgeAllowance) < BigInt(25000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve SMUDGE</button>
                                        ) : (
                                            token === 'SMUDGE' && BigInt(smudgeAllowance) >= BigInt(25000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of SMUDGE you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token === 'SUSU' && BigInt(susuAllowance) < BigInt(1000000 * 1e+18) ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve SUSU</button>
                                        ) : (
                                            token === 'SUSU' && BigInt(susuAllowance) >= BigInt(1000000 * 1e+18) ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of SUSU you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {token.slice(0, 2) === '0x' && unknownTokenApproved === false ? (
                                            <button onClick={approveTokenForBidding} className='bg-blue-700 dark:bg-blue-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Approve token</button>
                                        ) : (
                                            token.slice(0, 2) === '0x' && unknownTokenApproved === true ? (
                                                <div>
                                                    <input id={`bigAmountInput${auctionId}`} className='mb-[6px] mt-2 w-[95%] mx-[2.5%] rounded-md px-2 py-[3px]
                                    dark:bg-gradient-to-r dark:from-[rgba(154,239,162,0.2)] dark:via-[rgba(141,215,228,0.2)] dark:to-[rgba(200,161,251,0.2)] 
                                    bg-gradient-to-r from-[rgba(54,139,62,0.2)] via-[rgba(41,115,128,0.2)] to-[rgba(100,61,151,0.2)]
                                    border-[2px] border-[rgba(200,200,200,0.5)]' placeholder='Amount of tokens you want to bid' type="text" />
                                                    <button onClick={bidOnNFT} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Bid on NFT</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <button onClick={startApp} className='bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg w-[80%] h-[30px] flex items-center justify-center mx-auto'>Connect to place a bid</button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuctionCard;