// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    
    try {
      let account = await store.getState()["blockchain"]["account"];
      let totalAuctions = await store
        .getState()
        .blockchain.smartContract.methods.getTotalAuctions()
        .call();
      let wrappedBalance = await store
        .getState()
        .blockchain.wewtContract.methods.balanceOf(account)
        .call();

      let approveAllowanceWewt = await store
        .getState()
        .blockchain.wewtContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceShl = await store
        .getState()
        .blockchain.shlContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceRenbtc = await store
        .getState()
        .blockchain.renbtcContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceWeth = await store
        .getState()
        .blockchain.wethContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceDai = await store
        .getState()
        .blockchain.daiContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceOcean = await store
        .getState()
        .blockchain.oceanContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceCc = await store
        .getState()
        .blockchain.ccContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceSmudge = await store
        .getState()
        .blockchain.smudgeContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();
      let approveAllowanceSusu = await store
        .getState()
        .blockchain.susuContract.methods.allowance(account, CONFIG.CONTRACT_ADDRESS)
        .call();

      dispatch(
        fetchDataSuccess({
          account,
          totalAuctions,
          wrappedBalance,

          approveAllowanceWewt,
          approveAllowanceShl,
          approveAllowanceRenbtc,
          approveAllowanceWeth,
          approveAllowanceDai,
          approveAllowanceOcean,
          approveAllowanceCc,
          approveAllowanceSmudge,
          approveAllowanceSusu
        })
      );
    } catch (err) {
      //console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
