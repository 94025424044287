const About = () => {
    return (
        <div className="w-full min-h-[calc(100vh-64px)] flex justify-center align-start flex-col flex-nowrap dark:bg-bgprimary bg-darkbgprimary transition-all">
            <div className="w-full min-h-[calc(100vh-64px)] bg-backgroundimagepage bg-no-repeat bg-cover">
                <div className='dark:bg-bgsecondary bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 h-auto mx-auto my-4 sm:my-10 rounded-3xl
                    dark:shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
                    <div className="w-full pt-4 flex flex-col mx-auto">
                        <div className='dark:text-textprimary text-darktextprimary transition-all p-4 s:p-3 xs:p-2'>
                            <div className='mx-auto max-w-[800px]'>
                                <h1 className='mb-5 text-center font-bold text-2xl sm:text-3xl'>About EWCNFTAuctionHouse</h1>

                                <h2 className='mb-1 font-bold text-center text-lg sm:text-xl'>EWC NFT auction house fees</h2>
                                
                                <p className='text-center s:text-base text-lg sm:text-xl'>There is a 2% salefee on every auction that ends with a bid. 
                                The collected fees (minus the cashback people receive that own a turtle or a turtle with the corresponding coin artifact) will be used to buy SHL and EWT, to then be added to the EWT/SHL liquidity pool. 
                                If an auction used SHL as the token then those SHL will get burned.</p>

                                <h2 className='mb-1 mt-4 font-bold text-center text-lg sm:text-xl'>How does the 10%/25% cashback work?</h2>
                                
                                <p className='text-center s:text-base text-lg sm:text-xl'>
                                There is a 2% salefee for every auction that ends in a sale. Holding a Tubby Turtle will give you 10% of the fees back, and holding a turtle with the corresponding artifact of the token chosen for the sale will give you 25% of the fees back.
                                (this includes turtles on sea adventures). The cashbacks are automatically sent out when the NFT gets claimed by either the winner of the auction or the auction creator if the auction ended without a bid.
                                </p>

                                <h2 className='mb-1 mt-4 font-bold text-center text-lg sm:text-xl'>Disclaimer</h2>
                                
                                <p className='text-center s:text-base text-lg sm:text-xl'>This version of the auction house is a beta version. 
                                And for transparency: there is a withdraw function for ERC-721 tokens on the contract in the case where recovery is needed because of a bug for example.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;