import CreateAuctionForm from '../components/CreateAuctionForm';

const CreateAuction = () => {
    return (
        <div className="w-full min-h-[calc(100vh-64px)] flex justify-center align-start flex-col flex-nowrap dark:bg-bgprimary bg-darkbgprimary transition-all">
            <div className="w-full min-h-[calc(100vh-64px)] bg-backgroundimagepage bg-no-repeat bg-cover">
                <div className='dark:bg-bgsecondary bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 h-auto mx-auto my-4 sm:my-10 rounded-3xl
                    dark:shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
                    <div className="w-full pt-4 flex flex-col mx-auto">
                        <div className='dark:text-textprimary text-darktextprimary transition-all p-4 s:p-3 xs:p-2'>
                            <h1 className='mb-3 text-center font-bold s:text-xl text-2xl sm:text-3xl'>Create a NFT auction</h1>
                            <h2 className='mb-3 max-w-[800px] mx-auto text-center s:text-base text-lg sm:text-xl'>Once you start the auction you cannot cancel it, so make sure all the information you fill out down below is correct before you click 'create'.</h2>
                            <CreateAuctionForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateAuction;