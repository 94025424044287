import AuctionCard from '../components/AuctionCard';
import { db } from '../firebase';
import { onValue, ref } from 'firebase/database';
import { useState, useEffect } from 'react';

import auctionlogo from '../assets/images/auctionlogo.png';
// collections
import tubbyturtles from '../assets/images/tubbyturtles.png';
import cryptosoots from '../assets/images/cryptosoots.png';
import nfttrees from '../assets/images/nfttrees.png';
import iinu from '../assets/images/iinu.png';
import unknown from '../assets/images/unknown.png';

function SaleHistory() {
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        WEWT_CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        RENBTC_CONTRACT_ADDRESS: "",
        WETH_CONTRACT_ADDRESS: "",
        DAI_CONTRACT_ADDRESS: "",
        OCEAN_CONTRACT_ADDRESS: "",
        CC_CONTRACT_ADDRESS: "",
        SMUDGE_CONTRACT_ADDRESS: "",
        SUSU_CONTRACT_ADDRESS: "",

        TURTLES_CONTRACT_ADDRESS: "",
        SOOTS_CONTRACT_ADDRESS: "",
        TREES_CONTRACT_ADDRESS: "",
        IINU_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    // -----------------------------------------

    const [auctionsList, setAuctionsList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    const getSnapshotChildren = (snapshot) => {
        const children = [];
        snapshot.forEach(child => { children.push(child.val()) })
        return children;
    }

    useEffect(() => {
        onValue(ref(db), snapshot => {
            const auctions = getSnapshotChildren(snapshot);
            let sorted = [...auctions].sort((a, b) =>
            (parseInt(b.startTime)+parseInt(b.duration)).toString().localeCompare((parseInt(a.startTime)+parseInt(a.duration)).toString(), "en", {
                numeric: true
            }))
            sorted = sorted.filter(function(item) {
                return ((parseInt(item.startTime)+parseInt(item.duration)) < ((Math.round(Date.now()/1000))))
            })
            sorted = sorted.filter(function(item) {
                return (item.highestBidderAddress !== "0x0000000000000000000000000000000000000000")
            })
            setAuctionsList(sorted);
            setFilteredList(sorted);
        });
    }, []);

    function filterAll() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress !== '';
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterTurtles() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterSoots() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterTrees() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterIinu() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterUnknown() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return (
            item.nftAddress.toLowerCase() !== CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase()
            )
        });
        setFilteredList(newFilteredAuctions);
    }

    return (
        <div className="w-full min-h-[calc(100vh-64px)] flex justify-center align-start flex-col flex-nowrap dark:bg-bgprimary bg-darkbgprimary transition-all">
            <div className="w-full h-full bg-backgroundimagepage bg-no-repeat bg-cover">
                <div className='dark:bg-bgsecondary bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 h-auto mx-auto my-4 sm:my-10 rounded-3xl
                dark:shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
                    <div className="w-full min-h-[100vh] pt-4 flex flex-col mx-auto">
                        <div className='flex flex-col dark:text-textprimary text-darktextprimary transition-all xs:p-1 s:p-2 p-3 sm:p-4'>
                            <div>
                                <h1 className='mb-3 text-center font-bold text-2xl sm:text-3xl'>Sale history</h1>
                                <div className='mb-3'>
                                    <h1 className='text-center text-lg sm:text-xl'>The auction sale history (only auctions that sold are displayed here)</h1>
                                </div>
                            </div>

                            <div className='flex flex-row mx-auto my-1'>
                                <button onClick={filterAll}>
                                    <abbr title="Show auctions of all collections">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={auctionlogo} />
                                    </abbr>
                                </button>
                                <button onClick={filterTurtles}>
                                    <abbr title="Show all Tubby Turtles auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={tubbyturtles} />
                                    </abbr>
                                </button>
                                <button onClick={filterSoots}>
                                    <abbr title="Show all Cryptosoots auctions">
                                        <img className='hover:brightness-125 mx-2 w-8 rounded-full' src={cryptosoots} />
                                    </abbr>
                                </button>
                                <button onClick={filterTrees}>
                                    <abbr title="Show all NFTrees auctions">
                                        <img className='hover:brightness-[1.40] mx-2 w-8 rounded-full' src={nfttrees} />
                                    </abbr>
                                </button>
                                <button onClick={filterIinu}>
                                    <abbr title="Show all Iinu auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={iinu} />
                                    </abbr>
                                </button>
                                <button onClick={filterUnknown}>
                                    <abbr title="Show all unknown NFT auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={unknown} />
                                    </abbr>
                                </button>
                            </div>

                            <div className='flex flex-wrap justify-center'>
                                {filteredList.map((auction) => {
                                    return (
                                        <AuctionCard key={auction.auctionId}
                                        nftAddress={auction.nftAddress}
                                        nftId={auction.nftId}
                                        tokenAddress={auction.tokenAddress}
                                        startPrice={auction.startPrice}
                                        startTime={auction.startTime}
                                        duration={auction.duration}
                                        nftImgLink={auction.nftImgLink}
                                        currentBid={auction.currentBid}
                                        auctionId={auction.auctionId}
                                        creator={auction.creator}
                                        highestBidderAddress={auction.highestBidderAddress}
                                        assetClaimer={auction.assetClaimer}
                                        tokensClaimer={auction.tokensClaimer} />
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleHistory;
