const initialState = {
  loading: false,
  account: null,
  smartContract: null,

  turtleContract: null,
  sootsContract: null,
  treesContract: null,
  iinuContract: null,

  shlContract: null,
  renbtcContract: null,
  wethContract: null,
  daiContract: null,
  oceanContract: null,
  ccContract: null,
  smudgeContract: null,
  susuContract: null,

  wewtContract: null,
  web3: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,

        turtleContract: action.payload.turtleContract,
        sootsContract: action.payload.sootsContract,
        treesContract: action.payload.treesContract,
        iinuContract: action.payload.iinuContract,

        shlContract: action.payload.shlContract,
        renbtcContract: action.payload.renbtcContract,
        wethContract: action.payload.wethContract,
        daiContract: action.payload.daiContract,
        oceanContract: action.payload.oceanContract,
        ccContract: action.payload.ccContract,
        smudgeContract: action.payload.smudgeContract,
        susuContract: action.payload.susuContract,

        wewtContract: action.payload.wewtContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
