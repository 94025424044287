import AuctionCard from '../components/AuctionCard';
import { db, dbTwo } from '../firebase';
import { onValue, ref, get } from 'firebase/database';
import { useState, useEffect } from 'react';
import EwtWrapper from '../components/EwtWrapper';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import auctionlogo from '../assets/images/auctionlogo.png';
// collections
import tubbyturtles from '../assets/images/tubbyturtles.png';
import cryptosoots from '../assets/images/cryptosoots.png';
import nfttrees from '../assets/images/nfttrees.png';
import iinu from '../assets/images/iinu.png';
import unknown from '../assets/images/unknown.png';
// tokens 10 listed
import WEWT from '../assets/images/wrappedewtlogo.png';
import SHL from '../assets/images/shllogo.png';
import RENBTC from '../assets/images/renbtclogo.png';
import WETH from '../assets/images/ethlogo.png';
import DAI from '../assets/images/dailogo.png';
import OCEAN from '../assets/images/oceanlogo.png';
import CC from '../assets/images/cclogo.png';
import SMUDGE from '../assets/images/smudgelogo.png';
import SUSU from '../assets/images/susulogo.png';
import EWD from '../assets/images/ewdlogo.png';
import DOT from '../assets/images/dotlogo.png';


function Home() {
    const blockchain = useSelector((state) => state.blockchain);

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        WEWT_CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        RENBTC_CONTRACT_ADDRESS: "",
        WETH_CONTRACT_ADDRESS: "",
        DAI_CONTRACT_ADDRESS: "",
        OCEAN_CONTRACT_ADDRESS: "",
        CC_CONTRACT_ADDRESS: "",
        SMUDGE_CONTRACT_ADDRESS: "",
        SUSU_CONTRACT_ADDRESS: "",

        TURTLES_CONTRACT_ADDRESS: "",
        SOOTS_CONTRACT_ADDRESS: "",
        TREES_CONTRACT_ADDRESS: "",
        IINU_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    // -----------------------------------------

    const getSnapshotChildren = (snapshot) => {
        const children = [];
        snapshot.forEach(child => { 
            children.push(child.val()) ;
        })
        return children;
    }

    const [ccArtifacts, setCcArtifacts] = useState([]);
    const [dotArtifacts, setDotArtifacts] = useState([]);
    const [ewdArtifacts, setEwdArtifacts] = useState([]);
    const [ewtArtifacts, setEwtArtifacts] = useState([]);
    const [oceanArtifacts, setOceanArtifacts] = useState([]);
    const [renbtcArtifacts, setRenbtcArtifacts] = useState([]);
    const [shlArtifacts, setShlArtifacts] = useState([]);
    const [smudgeArtifacts, setSmudgeArtifacts] = useState([]);
    const [susuArtifacts, setSusuArtifacts] = useState([]);
    const [wethArtifacts, setWethArtifacts] = useState([]);
    async function getArtifactsOnce() {
        const snapshot = await get(ref(dbTwo, '/'))
        const snapshotVal = snapshot.val();
        setCcArtifacts(snapshotVal["CCturtles"]);
        setDotArtifacts(snapshotVal["DOTturtles"]);
        setEwdArtifacts(snapshotVal["EWDturtles"]);
        setEwtArtifacts(snapshotVal["EWTturtles"]);
        setOceanArtifacts(snapshotVal["OCEANturtles"]);
        setRenbtcArtifacts(snapshotVal["RENBTCturtles"]);
        setShlArtifacts(snapshotVal["SHLturtles"]);
        setSmudgeArtifacts(snapshotVal["SMUDGEturtles"]);
        setSusuArtifacts(snapshotVal["SUSUturtles"]);
        setWethArtifacts(snapshotVal["WETHturtles"]);
    }
    useEffect(() => {
        getArtifactsOnce();
    }, []);

    //useEffect(() => {
    //    //console.log('Has CC cashback: ',ccArtifacts.includes(blockchain.account))
    //    //console.log('Has DOT cashback: ',dotArtifacts.includes(blockchain.account))
    //    //console.log('Has EWD cashback: ',ewdArtifacts.includes(blockchain.account))
    //    //console.log('Has EWT cashback: ',ewtArtifacts.includes(blockchain.account))
    //    //console.log('Has OCEAN cashback: ',oceanArtifacts.includes(blockchain.account))
    //    //console.log('Has RENBTC cashback: ',renbtcArtifacts.includes(blockchain.account))
    //    //console.log('Has SHL cashback: ',shlArtifacts.includes(blockchain.account))
    //    //console.log('Has SMUDGE cashback: ',smudgeArtifacts.includes(blockchain.account))
    //    //console.log('Has SUSU cashback: ',susuArtifacts.includes(blockchain.account))
    //    //console.log('Has WETH cashback: ',wethArtifacts.includes(blockchain.account))
    //}, [blockchain.account, 
    //    ccArtifacts, dotArtifacts, ewdArtifacts, ewtArtifacts,
    //    oceanArtifacts, renbtcArtifacts, shlArtifacts, 
    //    smudgeArtifacts, susuArtifacts, wethArtifacts
    //]);

    const [auctionsList, setAuctionsList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        onValue(ref(db), snapshot => {
            console.log(snapshot)
            const auctions = getSnapshotChildren(snapshot);
            let sorted = [...auctions].sort((a, b) =>
            (parseInt(a.startTime)+parseInt(a.duration)).toString().localeCompare((parseInt(b.startTime)+parseInt(b.duration)).toString(), "en", {
                numeric: true
            }))
            sorted = sorted.filter(function(item) {
                return ((parseInt(item.startTime)+parseInt(item.duration)) > ((Math.round(Date.now()/1000)))-600)
            })
            setAuctionsList(sorted);
            setFilteredList(sorted);
        });
    }, []);

    function filterAll() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress !== '';
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterTurtles() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterSoots() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterTrees() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterIinu() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return item.nftAddress.toLowerCase() === CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase();
        });
        setFilteredList(newFilteredAuctions);
    }

    function filterUnknown() {
        let mainlist = auctionsList;
        console.log(mainlist);
        let newFilteredAuctions = mainlist.filter(function(item) {
            return (
            item.nftAddress.toLowerCase() !== CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase() &&
            item.nftAddress.toLowerCase() !== CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase()
            )
        });
        setFilteredList(newFilteredAuctions);
    }

    const [artifactsMenuOpen, setArtifactsMenuOpen] = useState(false);
    function switchArtifactsMenu() {
        if (artifactsMenuOpen === false) {
            setArtifactsMenuOpen(true);
        }
        else {
            setArtifactsMenuOpen(false);
        }
    }

    return (
        <div className="w-full min-h-[calc(100vh-64px)] flex justify-center align-start flex-col flex-nowrap dark:bg-bgprimary bg-darkbgprimary transition-all">
            <div className="w-full h-full bg-backgroundimagepage bg-no-repeat bg-cover">
                <div className='dark:bg-bgsecondary bg-darkbgsecondary w-[95%] md:w-[90%] lg:w-4/5 xl:w-3/4 2xl:w-2/3 h-auto mx-auto my-4 sm:my-10 rounded-3xl
                dark:shadow-[0_0px_10px_2px_rgba(15,23,35,0.30)] shadow-[0_0px_10px_2px_rgba(245,245,230,0.2)]'>
                    <div className="w-full min-h-[100vh] pt-4 flex flex-col mx-auto">
                        <div className='flex flex-col dark:text-textprimary text-darktextprimary transition-all xs:p-1 s:p-2 p-3 sm:p-4'>
                            <div>
                                <h1 className='mb-3 text-center font-bold text-2xl sm:text-3xl'>Energy Web Chain NFT Auction House</h1>
                                <h2 className='mx-auto max-w-[800px] mb-3 text-center text-lg sm:text-xl'>There is a 2% salefee for every auction that ends in a sale. Holding a Tubby Turtle will give you 10% of the fees back, and holding a turtle with the corresponding artifact of the token chosen for the sale will give you 25% of the fees back (even the turtles that are on sea adventures count). More details about this on the <Link className='bg-[rgba(0,0,255,0.05)] rounded-md px-[2px] text-blue-900 hover:text-blue-500 active:text-purple-500' to='/about'>'about'</Link> page</h2>
                                <div className='flex justify-center mb-2'>
                                    <button className='dark:bg-purple-300 bg-purple-500 rounded-lg hover:brightness-110 py-[2px] px-[8px]' onClick={switchArtifactsMenu}>Check owned artifacts</button>
                                </div>
                                {artifactsMenuOpen ? (
                                <div className='p-1 max-w-[700px] mx-auto justify-between flex flex-col sm:flex-row border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-xl'>
                                    <div className='flex flex-row justify-around sm:w-1/2'>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={CC} alt="" />
                                            {ccArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={DOT} alt="" />
                                            {dotArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={EWD} alt="" />
                                            {ewdArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={WEWT} alt="" />
                                            {ewtArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={OCEAN} alt="" />
                                            {oceanArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-around sm:w-1/2'>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={RENBTC} alt="" />
                                            {renbtcArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={SHL} alt="" />
                                            {shlArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={SMUDGE} alt="" />
                                            {smudgeArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={SUSU} alt="" />
                                            {susuArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                        <div className='m-1 sm:m-0 flex flex-col border-2 border-[rgb(40,40,40)] dark:border-[rgb(230,230,230)] rounded-lg'>
                                            <img className='m-[2px] w-8 2xs:w-7 3xs:w-6' src={WETH} alt="" />
                                            {wethArtifacts.includes(blockchain.account) ? (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="#4ec44e"/><path d="m22.273 33.783c-0.26014 0-0.51337-0.08748-0.7194-0.25208l-8.6328-6.9063c-0.4961-0.39711-0.57667-1.1211-0.17956-1.6172 0.39941-0.4961 1.1223-0.57667 1.6184-0.17956l7.674 6.1397 11.383-16.26c0.36373-0.52257 1.082-0.64689 1.6034-0.28316 0.52142 0.36488 0.64689 1.082 0.28316 1.6034l-12.086 17.266c-0.18186 0.26129-0.46387 0.43394-0.77926 0.47998-0.05524 0.0058-0.1105 0.0092-0.1646 0.0092z" fill="#3b5172" strokeWidth="1.151"/></svg>
                                            ) : (
                                                <svg className='m-[2px] w-8 2xs:w-7 3xs:w-6 opacity-[0.8]' version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g><circle cx="24" cy="24" r="20" fill="#d1323c"/></g><g fill="#3b5172" strokeWidth=".62964"><path d="m31.569 14.205 2.2265 2.2266-17.364 17.363-2.2265-2.2266z"/><path d="m33.794 31.57-2.2264 2.2258-17.362-17.366 2.2264-2.2258z"/></g></svg>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                ) : (
                                    <></>
                                )}
                                <EwtWrapper />
                            </div>
                            
                            <div className='flex flex-row mx-auto my-1'>
                                <button onClick={filterAll}>
                                    <abbr title="Show auctions of all collections">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={auctionlogo} />
                                    </abbr>
                                </button>
                                <button onClick={filterTurtles}>
                                    <abbr title="Show all Tubby Turtles auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={tubbyturtles} />
                                    </abbr>
                                </button>
                                <button onClick={filterSoots}>
                                    <abbr title="Show all Cryptosoots auctions">
                                        <img className='hover:brightness-125 mx-2 w-8 rounded-full' src={cryptosoots} />
                                    </abbr>
                                </button>
                                <button onClick={filterTrees}>
                                    <abbr title="Show all NFTrees auctions">
                                        <img className='hover:brightness-[1.40] mx-2 w-8 rounded-full' src={nfttrees} />
                                    </abbr>
                                </button>
                                <button onClick={filterIinu}>
                                    <abbr title="Show all Iinu auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={iinu} />
                                    </abbr>
                                </button>
                                <button onClick={filterUnknown}>
                                    <abbr title="Show all unknown NFT auctions">
                                        <img className='hover:brightness-110 mx-2 w-8 rounded-full' src={unknown} />
                                    </abbr>
                                </button>
                            </div>
                            
                            {filteredList.length > 0 ? (
                            <div className='flex flex-wrap justify-center'>
                                {filteredList.map((auction) => {
                                    return (
                                        <AuctionCard key={auction.auctionId}
                                        nftAddress={auction.nftAddress}
                                        nftId={auction.nftId}
                                        tokenAddress={auction.tokenAddress}
                                        startPrice={auction.startPrice}
                                        startTime={auction.startTime}
                                        duration={auction.duration}
                                        nftImgLink={auction.nftImgLink}
                                        currentBid={auction.currentBid}
                                        auctionId={auction.auctionId}
                                        creator={auction.creator}
                                        highestBidderAddress={auction.highestBidderAddress}
                                        assetClaimer={auction.assetClaimer}
                                        tokensClaimer={auction.tokensClaimer} />
                                    )
                                })}
                            </div>
                            ) : (
                            <div className='flex flex-wrap justify-center'>
                                <div className="auctioncard
                                    dark:bg-gradient-to-l dark:from-[rgba(154,239,162,0.6)] dark:via-[rgba(141,215,228,0.6)] dark:to-[rgba(200,161,251,0.6)] 
                                    bg-gradient-to-l from-[rgba(54,139,62,0.6)] via-[rgba(41,115,128,0.6)] to-[rgba(100,61,151,0.6)]
                                    transition-all m-3 sm:m-4 md:m-5 lg:m-6 w-[340px] s:w-full h-[580px] s:h-[630px] xs:h-[600px] 2xs:h-[540px] 3xs:h-[480px] 4xs:h-[460px] 5xs:h-[440px] rounded-2xl
                                    dark:shadow-[0_0px_12px_3px_rgba(15,23,35,0.30)] shadow-[0_0px_12px_3px_rgba(245,245,230,0.2)] p-[3px] sm:p-[4px] md:p-[5px]">
                                    <div className='pb-2 h-full w-full dark:bg-white bg-gray-800 rounded-lg px-1 2xs:text-sm 3xs:text-xs'>
                                        <div className='flex flex-col justify-center h-full'>
                                            <h1 className='text-center'>There are no active auctions right now</h1>
                                            <h1 className='text-center'>Go to the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/create-auction'>'Create'</Link> page to create an auction</h1>
                                            <div className='mt-2'>
                                                <h1 className='text-center text-sm my-2'>If you are looking for an auction that ended in a sale, you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/sale-history'>'Sale history'</Link> page</h1>
                                                <h1 className='text-center text-sm my-2'>Or if you are looking for just any auction created on EWCNFTAuctionHouse, then you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/auction-history'>'Auction history'</Link> page</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="auctioncard hidden 2xl:block
                                    dark:bg-gradient-to-l dark:from-[rgba(154,239,162,0.6)] dark:via-[rgba(141,215,228,0.6)] dark:to-[rgba(200,161,251,0.6)] 
                                    bg-gradient-to-l from-[rgba(54,139,62,0.6)] via-[rgba(41,115,128,0.6)] to-[rgba(100,61,151,0.6)]
                                    transition-all m-3 sm:m-4 md:m-5 lg:m-6 w-[340px] s:w-full h-[580px] s:h-[630px] xs:h-[600px] 2xs:h-[540px] 3xs:h-[480px] 4xs:h-[460px] 5xs:h-[440px] rounded-2xl
                                    dark:shadow-[0_0px_12px_3px_rgba(15,23,35,0.30)] shadow-[0_0px_12px_3px_rgba(245,245,230,0.2)] p-[3px] sm:p-[4px] md:p-[5px]">
                                    <div className='pb-2 h-full w-full dark:bg-white bg-gray-800 rounded-lg px-1 2xs:text-sm 3xs:text-xs'>
                                        <div className='flex flex-col justify-center h-full'>
                                            <h1 className='text-center'>There are no active auctions right now</h1>
                                            <h1 className='text-center'>Go to the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/create-auction'>'Create'</Link> page to create an auction</h1>
                                            <div className='mt-2'>
                                                <h1 className='text-center text-sm my-2'>If you are looking for an auction that ended in a sale, you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/sale-history'>'Sale history'</Link> page</h1>
                                                <h1 className='text-center text-sm my-2'>Or if you are looking for just any auction created on EWCNFTAuctionHouse, then you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/auction-history'>'Auction history'</Link> page</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="auctioncard hidden lg:block
                                    dark:bg-gradient-to-l dark:from-[rgba(154,239,162,0.6)] dark:via-[rgba(141,215,228,0.6)] dark:to-[rgba(200,161,251,0.6)] 
                                    bg-gradient-to-l from-[rgba(54,139,62,0.6)] via-[rgba(41,115,128,0.6)] to-[rgba(100,61,151,0.6)]
                                    transition-all m-3 sm:m-4 md:m-5 lg:m-6 w-[340px] s:w-full h-[580px] s:h-[630px] xs:h-[600px] 2xs:h-[540px] 3xs:h-[480px] 4xs:h-[460px] 5xs:h-[440px] rounded-2xl
                                    dark:shadow-[0_0px_12px_3px_rgba(15,23,35,0.30)] shadow-[0_0px_12px_3px_rgba(245,245,230,0.2)] p-[3px] sm:p-[4px] md:p-[5px]">
                                    <div className='pb-2 h-full w-full dark:bg-white bg-gray-800 rounded-lg px-1 2xs:text-sm 3xs:text-xs'>
                                        <div className='flex flex-col justify-center h-full'>
                                            <h1 className='text-center'>There are no active auctions right now</h1>
                                            <h1 className='text-center'>Go to the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/create-auction'>'Create'</Link> page to create an auction</h1>
                                            <div className='mt-2'>
                                                <h1 className='text-center text-sm my-2'>If you are looking for an auction that ended in a sale, you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/sale-history'>'Sale history'</Link> page</h1>
                                                <h1 className='text-center text-sm my-2'>Or if you are looking for just any auction created on EWCNFTAuctionHouse, then you can find it on the <Link className='font-bold hover:text-blue-500 active:text-purple-500' to='/auction-history'>'Auction history'</Link> page</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
