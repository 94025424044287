import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';

import ewt from '../assets/images/ewtlogo.png';
import wewt from '../assets/images/wrappedewtlogo.png';
import swap from '../assets/images/swap.png';
import swaptokens from '../assets/images/swaptokens.png';

const EwtWrapper = () => {
    /* global BigInt */
    // ----------------------------------------------
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        WEWT_CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        RENBTC_CONTRACT_ADDRESS: "",
        WETH_CONTRACT_ADDRESS: "",
        DAI_CONTRACT_ADDRESS: "",
        OCEAN_CONTRACT_ADDRESS: "",
        CC_CONTRACT_ADDRESS: "",
        SMUDGE_CONTRACT_ADDRESS: "",
        SUSU_CONTRACT_ADDRESS: "",

        TURTLES_CONTRACT_ADDRESS: "",
        SOOTS_CONTRACT_ADDRESS: "",
        TREES_CONTRACT_ADDRESS: "",
        IINU_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);
    // ----------------------------------------------

    const [braveShieldError, setBraveShieldError] = useState(false);
    const [userEwtBalance, setUserEwtBalance] = useState('?');
    const getUserEWTBalance = async () => {
        if (blockchain.account !== null) {
            try {
                const response = await fetch(
                    `https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=${blockchain.account}`
                ).then((response) => response.json());
                if (response["result"] !== null && response["result"] !== undefined) {
                    console.log(response["result"])
                    const hexToDecimal = hex => parseInt(hex, 16);
                    setUserEwtBalance(parseInt(BigInt(hexToDecimal(String(response["result"])))/BigInt(10000000000000000))/100);
                } 
            } catch {setBraveShieldError(true)}
        }
    };

    useEffect(() => {
        getUserEWTBalance();
    }, [blockchain.account]);

    const [userWrappedEwtBalance, setUserWrappedEwtBalance] = useState('?');
    function getUserWrappedEWTBalance() {
        if (BigInt(data.wrappedBalance) > BigInt(0)) {
            setUserWrappedEwtBalance(parseInt(BigInt(data.wrappedBalance)/BigInt(10000000000000000))/100);
        }
    }

    useEffect(() => {
        getUserWrappedEWTBalance();
    }, [data.wrappedBalance]);

    const [wrappedMenuIsOpen, setWrappedMenuIsOpen] = useState(false);
    function switchIsOpenState() {
        if (wrappedMenuIsOpen === false) {
            setWrappedMenuIsOpen(true);
        }
        else {
            setWrappedMenuIsOpen(false);
        }
    }

    const [swapTokensState, setSwapTokensState] = useState(false);
    function swapSwapTokens() {
        if (swapTokensState === false) {
            setSwapTokensState(true);
        }
        else {
            setSwapTokensState(false);
        }
    }

    function wrapEWT() {
        let amountToWrap;
        if (parseInt(document.getElementById('amountToWrap').value) > 0) {
            amountToWrap = BigInt(Math.round(document.getElementById('amountToWrap').value))*BigInt(1e+18)
        }
        else {
            amountToWrap = (document.getElementById('amountToWrap').value)*(1e+18)
        }
        console.log(amountToWrap)
        blockchain.wewtContract.methods.deposit().send({
            to: CONFIG.WEWT_CONTRACT_ADDRESS,
            from: blockchain.account,
            value: String(amountToWrap),
            gasPrice: 100000000,
        }).then((receipt) => {
            console.log({receipt}.receipt)
            if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                setTimeout(function() {
                    getUserEWTBalance();
                    getUserWrappedEWTBalance();
                }, 1000);
            }
        });
    }

    function unwrapWEWT() {
        let totalCostWei = String(0);
        let amountToWrap;
        if (parseInt(document.getElementById('amountToUnwrap').value) > 0) {
            amountToWrap = BigInt(Math.round(document.getElementById('amountToUnwrap').value))*BigInt(1e+18)
        }
        else {
            amountToWrap = (document.getElementById('amountToUnwrap').value)*(1e+18)
        }
        console.log(amountToWrap)
        blockchain.wewtContract.methods.withdraw(String(amountToWrap)).send({
            to: CONFIG.WEWT_CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gasPrice: 100000000,
        }).then((receipt) => {
            console.log({receipt}.receipt)
            if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                setTimeout(function() {
                    getUserEWTBalance();
                    getUserWrappedEWTBalance();
                }, 1000);
            }
        });
    }

    return (
        <div>
        {wrappedMenuIsOpen === true ? (
            <div className='bg-[rgba(220,220,255,0.3)] m-3 p-3 sm:p-4 rounded-xl mx-auto w-full sm:w-[85%] md:w-[75%] lg:w-[60%] xl:w-[50%]'>
                <h1 onClick={switchIsOpenState} className='select-none text-center hover:cursor-pointer hover:text-blue-500 active:text-purple-500'>Close the EWTWrapper & WEWTUnwrapper</h1>
                <div className='justify-center flex flex-row'>
                    <span><img className='w-8 mx-1' src={ewt} alt="" /></span>
                    <span className='flex my-auto'><img className='w-5 mx-1' src={swap} alt="" /></span>
                    <span><img className='w-8 mx-1' src={wewt} alt="" /></span>
                </div>
                <h2 className='text-center'>{userEwtBalance} <span><img className='w-4 -translate-y-[2px] mx-[3px] inline' src={ewt} alt="" /></span></h2>
                <h2 className='text-center'>{userWrappedEwtBalance} <span><img className='w-4 -translate-y-[2px] mx-[3px] inline' src={wewt} alt="" /></span></h2>
                <div className='flex flex-col justify-center'>
                    {swapTokensState === false ? (
                    <div>
                        <div className='mx-auto rounded-md m-1 flex flex-row bg-[rgba(215,255,215,0.7)] justify-center w-fit py-1 px-3'>
                            <input id='amountToWrap' className='rounded-md w-full px-[8px] py-[3px] my-2' placeholder='EWT amount to wrap' type="text" />
                            <img className='ml-1 h-[30px] my-auto aspect-square' src={ewt} alt="" />
                        </div>
                        <div className='flex mx-auto justify-center'>
                            <img onClick={swapSwapTokens} className='hover:invert hover:cursor-pointer w-8 rotate-90' src={swaptokens} alt="" />
                        </div>
                        <div className='mx-auto rounded-md m-1 flex flex-row bg-[rgba(215,255,215,0.7)] justify-center w-fit py-1 px-3'>
                            <input className='rounded-md w-full px-[8px] py-[3px] my-2' placeholder='WEWT amount to unwrap' type="text" readOnly disabled />
                            <img className='ml-1 h-[30px] my-auto aspect-square' src={wewt} alt="" />
                        </div>
                        <div className='flex justify-center mt-2'>
                            <button onClick={wrapEWT} className='py-[3px] px-3 rounded-lg bg-green-700 dark:bg-green-300 hover:brightness-110'>
                                Wrap your EWT
                            </button>
                        </div>
                    </div>
                    ) : (
                    <div>
                        <div className='mx-auto rounded-md m-1 flex flex-row bg-[rgba(215,255,215,0.7)] justify-center w-fit py-1 px-3'>
                            <input id='amountToUnwrap' className='rounded-md w-full px-[8px] py-[3px] my-2' placeholder='WEWT amount to unwrap' type="text"  />
                            <img className='ml-1 h-[30px] my-auto aspect-square' src={wewt} alt="" />
                        </div>
                        <div className='flex mx-auto justify-center'>
                            <img onClick={swapSwapTokens} className='hover:invert hover:cursor-pointer w-8 rotate-90' src={swaptokens} alt="" />
                        </div>
                        <div className='mx-auto rounded-md m-1 flex flex-row bg-[rgba(215,255,215,0.7)] justify-center w-fit py-1 px-3'>
                            <input className='rounded-md w-full px-[8px] py-[3px] my-2' placeholder='EWT amount to wrap' type="text" readOnly disabled />
                            <img className='ml-1 h-[30px] my-auto aspect-square' src={ewt} alt="" />
                        </div>
                        <div className='flex justify-center mt-2'>
                            <button onClick={unwrapWEWT} className='py-[3px] px-3 rounded-lg bg-green-700 dark:bg-green-300 hover:brightness-110'>
                                Unwrap your WEWT
                            </button>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            ) : (
                <div className='bg-[rgba(220,220,255,0.3)] m-3 p-3 sm:p-4 rounded-xl mx-auto w-full sm:w-[85%] md:w-[75%] lg:w-[60%] xl:w-[50%]'>
                <h1 onClick={switchIsOpenState} className='select-none hover:cursor-pointer hover:text-blue-500 active:text-purple-500 text-center'>Open the EWTWrapper & WEWTUnwrapper</h1>
                <div className='flex flex-col items-center'>
                    <h2 className='text-center flex flex-row'>EWT Balance: {userEwtBalance} <span className='flex justify-center'><img className='ml-1 h-4 my-auto aspect-square' src={ewt} alt="" /></span></h2>
                    <h2 className='text-center flex flex-row'>WEWT Balance: {userWrappedEwtBalance} <span className='flex justify-center'><img className='ml-1 h-4 my-auto aspect-square' src={wewt} alt="" /></span></h2>
                </div>
            </div>
            )}
        </div>
    );
}

export default EwtWrapper;