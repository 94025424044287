import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchData } from '../redux/data/dataActions';
import { connect } from '../redux/blockchain/blockchainActions';
import ewnftauctionlogo from '../assets/images/auctionlogo.png';
import ReactCanvasConfetti from 'react-canvas-confetti';
import AuctionCard from './AuctionCard';

const CreateAuctionForm = () => {
    /* global BigInt */
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        WEWT_CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        RENBTC_CONTRACT_ADDRESS: "",
        WETH_CONTRACT_ADDRESS: "",
        DAI_CONTRACT_ADDRESS: "",
        OCEAN_CONTRACT_ADDRESS: "",
        CC_CONTRACT_ADDRESS: "",
        SMUDGE_CONTRACT_ADDRESS: "",
        SUSU_CONTRACT_ADDRESS: "",

        TURTLES_CONTRACT_ADDRESS: "",
        SOOTS_CONTRACT_ADDRESS: "",
        TREES_CONTRACT_ADDRESS: "",
        IINU_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    // ----------------------------------------------
    function approveNftOfSelectedCollection() {
        let approveNftId; let totalCostWei; let nftCollectionAddress;
            approveNftId = document.getElementById('nftId').value;
            totalCostWei = String(0);
            nftCollectionAddress = document.getElementById('nftCollection').value;
            if (nftCollectionAddress === "TubbyTurtles") { 
                nftCollectionAddress = CONFIG.TURTLES_CONTRACT_ADDRESS.toLowerCase();
                try {
                    blockchain.turtleContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS).toLowerCase(), String(approveNftId))
                    .send({
                        to: nftCollectionAddress,
                        from: blockchain.account,
                        value: totalCostWei,
                        gasPrice: 100000000,
                    }).then((receipt) => {
                        console.log({receipt}.receipt)
                        if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                            changeNFTApproved();
                        }
                    });
                } catch {
                    document.getElementById('errorMsg').innerText = 'You may have not filled out all the required information';
                    setTimeout(function() {
                        document.getElementById('errorMsg').innerText = ''
                    }, 10000);
                }
            }
            else if (nftCollectionAddress === "Cryptosoots") { 
                nftCollectionAddress = CONFIG.SOOTS_CONTRACT_ADDRESS.toLowerCase();
                try {
                    blockchain.sootsContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS).toLowerCase(), String(approveNftId))
                    .send({
                        to: nftCollectionAddress,
                        from: blockchain.account,
                        value: totalCostWei,
                        gasPrice: 100000000,
                    }).then((receipt) => {
                        console.log({receipt}.receipt)
                        if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                            changeNFTApproved();
                        }
                    });
                } catch {
                    document.getElementById('errorMsg').innerText = 'You may have not filled out all the required information';
                    setTimeout(function() {
                        document.getElementById('errorMsg').innerText = ''
                    }, 10000);
                }
            }
            else if (nftCollectionAddress === "NFTrees") { 
                nftCollectionAddress = CONFIG.TREES_CONTRACT_ADDRESS.toLowerCase();
                try {
                    blockchain.treesContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS).toLowerCase(), String(approveNftId))
                    .send({
                        to: nftCollectionAddress,
                        from: blockchain.account,
                        value: totalCostWei,
                        gasPrice: 100000000,
                    }).then((receipt) => {
                        console.log({receipt}.receipt)
                        if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                            changeNFTApproved();
                        }
                    });
                } catch {
                    document.getElementById('errorMsg').innerText = 'You may have not filled out all the required information';
                    setTimeout(function() {
                        document.getElementById('errorMsg').innerText = ''
                    }, 10000);
                }
            }
            else if (nftCollectionAddress === "Iinu") { 
                nftCollectionAddress = CONFIG.IINU_CONTRACT_ADDRESS.toLowerCase();
                try {
                    blockchain.iinuContract.methods.approve(String(CONFIG.CONTRACT_ADDRESS).toLowerCase(), String(approveNftId))
                    .send({
                        to: nftCollectionAddress,
                        from: blockchain.account,
                        value: totalCostWei,
                        gasPrice: 100000000,
                    }).then((receipt) => {
                        console.log({receipt}.receipt)
                        if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                            changeNFTApproved();
                        }
                    });
                } catch {
                    document.getElementById('errorMsg').innerText = 'You may have not filled out all the required information';
                    setTimeout(function() {
                        document.getElementById('errorMsg').innerText = ''
                    }, 10000);
                }
            }
    }

    const [nftAddress, setnftAddress] = useState("");
    const [nftId, setnftId] = useState("");
    const [tokenAddress, settokenAddress] = useState("");
    const [startPrice, setstartPrice] = useState("");
    const [startTime, setstartTime] = useState("");
    const [duration, setduration] = useState("");
    const [nftImgLink, setnftImgLink] = useState("");
    const [currentBid, setcurrentBid] = useState("");
    const [auctionId, setauctionId] = useState("");
    const [creator, setcreator] = useState("");
    const [highestBidderAddress, sethighestBidderAddress] = useState("");
    const [assetClaimer, setassetClaimer] = useState("");
    const [tokensClaimer, settokensClaimer] = useState("");

    function createNFTAuction() {
        let nftCollection = document.getElementById('nftCollection').value;
        let nftId = document.getElementById('nftId').value;
        let nftImgLink;
            if (nftCollection === "TubbyTurtles") { 
                nftCollection = CONFIG.TURTLES_CONTRACT_ADDRESS;
                nftImgLink = `https://storage.googleapis.com/turtlestubby/${nftId}.png`;
            }
            else if (nftCollection === "Cryptosoots") { 
                nftCollection = CONFIG.SOOTS_CONTRACT_ADDRESS;
                nftImgLink = `https://s.soots.cc/soots/${nftId}.png`;
            }
            else if (nftCollection === "NFTrees") { 
                nftCollection = CONFIG.TREES_CONTRACT_ADDRESS;
                nftImgLink = `https://nftrees.cc/trees/${nftId}.png`;
            }
            else if (nftCollection === "Iinu") { 
                nftCollection = CONFIG.IINU_CONTRACT_ADDRESS;
                nftImgLink = `https://gateway.pinata.cloud/ipfs/QmYY4n9AyLviir6PDGUzJc7Tb631SY5uPnMDGKqMpBbqeG/${nftId}.png`;
            }
            else {
                nftCollection = "";
                nftImgLink = `https://auction.energywebnfts.com/unknown.png`;
            }
        let token = document.getElementById('token').value;
            if (token === "WEWT") { token = CONFIG.WEWT_CONTRACT_ADDRESS;}
            else if (token === "SHL") { token = CONFIG.SHL_CONTRACT_ADDRESS;}
            else if (token === "RENBTC") { token = CONFIG.RENBTC_CONTRACT_ADDRESS;}
            else if (token === "WETH") { token = CONFIG.WETH_CONTRACT_ADDRESS;}
            else if (token === "DAI") { token = CONFIG.DAI_CONTRACT_ADDRESS;}
            else if (token === "OCEAN") { token = CONFIG.OCEAN_CONTRACT_ADDRESS;}
            else if (token === "CC") { token = CONFIG.CC_CONTRACT_ADDRESS;}
            else if (token === "SMUDGE") { token = CONFIG.SMUDGE_CONTRACT_ADDRESS;}
            else if (token === "SUSU") { token = CONFIG.SUSU_CONTRACT_ADDRESS;}
        let startPrice = document.getElementById('startPrice').value;
            startPrice = BigInt(parseFloat(startPrice) * 10000) * BigInt(1e+18) / BigInt(10000)
        let startTime = Math.round(Date.now()/1000);
        let durationDays = document.getElementById('durationDays').value;
        let durationHours = document.getElementById('durationHours').value;
        let durationMinutes = document.getElementById('durationMinutes').value;
        let durationFinalAmount = (durationDays*60*60*24)+(durationHours*60*60)+(durationMinutes*60)

        let highestBidderAddress = blockchain.account;
        let creator = blockchain.account;

        console.log(nftCollection, nftId, token, startPrice, startTime, durationFinalAmount)

        let totalCostWei = String(0);
        blockchain.smartContract.methods.createAuction(
            String(nftCollection), 
            String(nftId),
            String(token),
            String(startPrice),
            String(startTime),
            String(durationFinalAmount)
        ).send({
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gasPrice: 100000000,
        }).then((receipt) => {
            console.log({receipt}.receipt)
            if ({receipt}.receipt.blockHash !== undefined && {receipt}.receipt.blockHash !== "") {
                setnftAddress(nftCollection);
                setnftId(nftId);
                settokenAddress(token);
                setstartPrice(startPrice);
                setstartTime(startTime);
                setduration(durationFinalAmount);
                setnftImgLink(nftImgLink);
                setcurrentBid(0);
                setauctionId("");
                setcreator(blockchain.account);
                sethighestBidderAddress("0x0000000000000000000000000000000000000000");
                setassetClaimer("0x0000000000000000000000000000000000000000");
                settokensClaimer("0x0000000000000000000000000000000000000000");
                document.getElementById("myModal").style.display = "block";
                console.log('Auction created')
                fire();
            }
        });
    }

    const [hasNFTApproved, setHasNFTApproved] = useState(false);
    function changeNFTApproved() {
        setHasNFTApproved(true);
    }

    const canvasStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }

    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
            refAnimationInstance.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio)
            });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 40,
            startVelocity: 60
        });

        makeShot(0.2, {
            spread: 85
        });

        makeShot(0.35, {
            spread: 120,
            decay: 0.91,
            scalar: 0.8
        });

        makeShot(0.1, {
            spread: 150,
            startVelocity: 30,
            decay: 0.92,
            scalar: 1.2
        });

        makeShot(0.1, {
            spread: 150,
            startVelocity: 50
        });
    }, [makeShot]);

    useEffect(() => {
        var modal = document.getElementById("myModal");

        var span = document.getElementsByClassName("close")[0];

        span.onclick = function () {
            modal.style.display = "none";
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }
    });


    return (
        <div className='sm:text-lg mt-2 mx-auto w-[95%] sm:w-[85%] md:w-[80%] lg:w-[75%] xl:w-[70%] 2xl:w-[60%] dark:bg-bgprimary bg-darkbgprimary shadow-md rounded-xl'>
            <div className='p-10 s:p-8 xs:p-6 2xs:p-3'>
                <img className='w-16 mx-auto mb-2 aspect-square' src={ewnftauctionlogo} alt="" />
                <div className='text-center'>
                    <div>
                        <label>NFT Collection</label>
                    </div>
                    <div>
                        <select className='bg-[rgba(100,255,100,0.1)] p-1 rounded-lg' id="nftCollection" name="nftCollection">
                            <option className='dark:bg-[rgba(100,255,100,0.1)] bg-[rgba(4,34,5,0.9)]' value="ChooseOne">Choose collection</option>
                            <option className='dark:bg-[rgba(100,255,100,0.1)] bg-[rgba(4,34,5,0.9)]' value="TubbyTurtles">Tubby Turtles</option>
                            <option className='dark:bg-[rgba(100,255,100,0.1)] bg-[rgba(4,34,5,0.9)]' value="Cryptosoots">Cryptosoots</option>
                            <option className='dark:bg-[rgba(100,255,100,0.1)] bg-[rgba(4,34,5,0.9)]' value="NFTrees">NFTrees</option>
                            <option className='dark:bg-[rgba(100,255,100,0.1)] bg-[rgba(4,34,5,0.9)]' value="Iinu">Iinu</option>
                        </select>
                    </div>
                </div>
                <div className='text-center'>
                    <div>
                        <label>NFT ID</label>
                    </div>
                    <div>
                        <input className='bg-[rgba(100,255,177,0.1)] px-2 p-1 rounded-lg' type="text" id="nftId" name="nftId" placeholder="ID of NFT" />
                    </div>
                </div>
                <div className='text-center'>
                    <div>
                        <label>Token</label>
                    </div>
                    <div>
                        <select className='bg-[rgba(100,255,203,0.1)] 2xs:w-[210px] p-1 rounded-lg' id="token" name="token">
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="ChooseOne">Choose token</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="WEWT">Wrapped EWT (WEWT)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="SHL">SeaShell (SHL)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="RENBTC">renBTC (renBTC)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="WETH">Wrapped Ether (WETH)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="DAI">Dai (DAI)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="OCEAN">Ocean (OCEAN)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="CC">Carbon Credits - sootopia.cc (CC)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="SMUDGE">Smudgecoin (SMUDGE)</option>
                            <option className='bg-[rgba(0,38,26,0.9)] dark:bg-[rgba(100,255,203,0.1)]' value="SUSU">Susu Token (SUSU)</option>
                        </select>
                    </div>
                </div>
                <div className='text-center'>
                    <div>
                        <label>Start price</label>
                    </div>
                    <div>
                        <input className='w-[235px] 2xs:w-[210px] bg-[rgba(100,245,255,0.1)] px-2 p-1 rounded-lg' type="text" id="startPrice" name="startPrice" placeholder="Startprice in selected token" />
                    </div>
                </div>
                {/*
                <div>
                    <div>
                        <label>Start time</label>
                    </div>
                    <div>
                        <input className='bg-[rgba(100,185,255,0.1)] px-2 p-1 rounded-lg' type="text" id="startTime" name="startTime" placeholder="Starttime" />
                    </div>
                </div>
                */}
                <div className='text-center'>
                    <div>
                        <label>Duration</label>
                    </div>
                    <div className='flex flex-row'>
                        <div className='ml-auto flex flex-col justify-between'>
                            <label>Days</label>
                            <input className='mx-auto bg-[rgba(134,100,255,0.1)] px-2 p-1 rounded-lg min-w-[50px] w-[5vw]' defaultValue={1} type="text" id="durationDays" name="duration" placeholder="Duration" />
                        </div>
                        <div className='mx-3 flex flex-col justify-between'>
                            <label>Hours</label>
                            <input className='mx-auto bg-[rgba(134,100,255,0.1)] px-2 p-1 rounded-lg min-w-[50px] w-[5vw]' defaultValue={0} type="text" id="durationHours" name="duration" placeholder="Duration" />
                        </div>
                        <div className='mr-auto flex flex-col justify-between'>
                            <label>Minutes</label>
                            <input className='mx-auto bg-[rgba(134,100,255,0.1)] px-2 p-1 rounded-lg min-w-[50px] w-[5vw]' defaultValue={0} type="text" id="durationMinutes" name="duration" placeholder="Duration" />
                        </div>
                    </div>
                </div>
                {blockchain.account === "" || blockchain.account === null ? (
                <div className='text-center mt-2 flex flex-col'>
                    <p id='errorMsg' className='text-center dark:text-red-600 text-red-800'></p>
                    <button onClick={startApp} className='font-bold py-1 px-5 my-1 w-fit bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg h-[30px] flex items-center justify-center mx-auto'>Connect</button>
                    <div className='mx-auto m-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" version="1.1" viewBox="0 0 13.229 13.229">
                        <g fill="none" stroke="#000" strokeDasharray="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.323" paintOrder="markers stroke fill">
                            <path d="M6.615.794v11.641l-2.91-3.704"></path>
                            <path d="M6.615 12.435l2.91-3.704"></path>
                        </g>
                    </svg>
                    </div>
                    <button onClick={startApp} className='font-bold py-1 px-5 my-1 w-fit bg-green-700 dark:bg-green-300 hover:brightness-110 rounded-lg h-[30px] flex items-center justify-center mx-auto'>Connect</button>
                </div>
                ) : (
                    <div className='text-center mt-2 flex flex-col'>
                    <p id='errorMsg' className='text-center dark:text-red-600 text-red-800'></p>
                    <abbr title="Approves the selected NFT for transfer from your wallet to the auction smartcontract to start the auction">
                        <button onClick={approveNftOfSelectedCollection} className='my-1 w-fit mx-auto bg-blue-500 dark:bg-blue-400 py-1 px-5 rounded-md hover:brightness-[0.8] dark:hover:brightness-110'><span className='font-bold'>Approve</span></button>
                    </abbr>
                    <div className='mx-auto m-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" version="1.1" viewBox="0 0 13.229 13.229">
                        <g fill="none" stroke="#000" strokeDasharray="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.323" paintOrder="markers stroke fill">
                            <path d="M6.615.794v11.641l-2.91-3.704"></path>
                            <path d="M6.615 12.435l2.91-3.704"></path>
                        </g>
                    </svg>
                    </div>
                    {hasNFTApproved === false ? (
                        <button id='createNFTAuction' disabled={true} onClick={createNFTAuction} className='my-1 w-fit mx-auto bg-green-900 dark:bg-green-100 py-1 px-5 rounded-md'><span className='font-bold'>Create</span></button>
                    ) : (
                        <button id='createNFTAuction' onClick={createNFTAuction} className='my-1 w-fit mx-auto bg-green-500 dark:bg-green-400 py-1 px-5 rounded-md hover:brightness-[0.8] dark:hover:brightness-110'><span className='font-bold'>Create</span></button>
                    )}
                </div>
                )}
                {/*
                <button onClick={TestFunc} className='mx-auto flex bg-red-600 py-1 px-5 rounded-md'><span className='hover:invert font-bold'>Test Function</span></button>
                */}
            </div>

            <div id="myModal" className="z-[99] modal">
                <div id='myModalContent' className="modal-content rounded-3xl w-[90%] sm:w-[85%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%]">
                    <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
                    <span className="close">&times;</span>
                    <h1 className='text-center font-bold text-lg sm:text-xl md:text-2xl'>You just created an auction for your NFT!</h1>
                    <AuctionCard
                        nftAddress={nftAddress}
                        nftId={nftId}
                        tokenAddress={tokenAddress}
                        startPrice={startPrice}
                        startTime={startTime}
                        duration={duration}
                        nftImgLink={nftImgLink}
                        currentBid={currentBid}
                        auctionId={auctionId}
                        creator={creator}
                        highestBidderAddress={highestBidderAddress}
                        assetClaimer={assetClaimer}
                        tokensClaimer={tokensClaimer} />
                </div>
            </div>
        </div>
    );
}

export default CreateAuctionForm;